<template>
  <b-overlay :show="loading" :opacity="0.5">
  <section>
    <div class="d-none d-md-block">
      <div class="mt-md-4">
        <list-table
            :records="loans"
            :columns="tableColumns"
            :total-records="totalRecords"
            :show-search="false"
            :show-filter-toggle-button="false"
            :current-page-number.sync="currentPage"
            @row-clicked="onRowClicked"
        >
          <template #action_button>
            <b-button
                variant="primary"
                :to="{ name: 'client-select-loan-product' }"
                :disabled="!isLoansModuleEnabled"
            >
              <span class="text-nowrap">Get New Loan</span>
            </b-button>
          </template>

          <template #filters>
            <loan-list-filters :status-filter.sync="statusFilter" />
          </template>

          <template #cell(show_details)="row">
            <b-button variant="outline" @click="row.toggleDetails">
              <feather-icon v-if="row.detailsShowing" icon="ChevronUpIcon" size="18" />
              <feather-icon v-else icon="ChevronDownIcon" size="18" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card class="bg-light">
              <b-row class="mb-2">
                <b-col>
                  <b-badge pill :variant="`light-${resolveStatusVariant(row.item.status)}`" class="text-capitalize">
                    {{
                      ['pending-product-delivery', 'flagged', 'admin-approve'].includes(row.item.status) ? 'Pending' : capitalizeFirstWord(row.item.status)
                    }}
                  </b-badge>
                </b-col>
              </b-row>

              <b-row v-if="row.item.purpose" class="mb-2">
                <b-col sm="3" class="font-weight-bold">
                  Purpose:
                </b-col>
                <!-- {{ row }} -->
                <b-col><span>{{ capitalizeFirstWord(row.item.purpose) }}</span></b-col>
              </b-row>

              <b-row v-if="row.item.location" class="mb-2">
                <b-col sm="3" class="font-weight-bold">
                  Location:
                </b-col>
                <!-- {{ row }} -->
                <b-col><span>{{ capitalizeFirstWord(row.item.location) }}</span></b-col>
              </b-row>

              <b-row v-if="row.item.landmark" class="mb-2">
                <b-col sm="3" class="font-weight-bold">
                  Landmark:
                </b-col>
                <b-col><span>{{ capitalizeFirstWord(row.item.landmark) }}</span></b-col>
              </b-row>

              <b-row v-if="row.item.reason_for_loan" class="mb-2">
                <b-col sm="3" class="font-weight-bold">
                  Reason for loan:
                </b-col>
                <b-col><span>{{ capitalizeFirstWord(row.item.reason_for_loan) }}</span></b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col sm="3" class="font-weight-bold">
                  Amount:
                </b-col>
                <b-col><span>{{ formatMoney(row.item.amount) }}</span></b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col sm="3" class="font-weight-bold">
                  Date:
                </b-col>
                <b-col><span>{{ formatDate(row.item.created) }}</span></b-col>
              </b-row>

            </b-card>
          </template>

          <!-- Column: Purpose -->
          <template #cell(purpose)="data">
            <span>{{ capitalizeFirstWord(data.value) }}</span>
          </template>

          <!-- Column: Location -->
          <template #cell(location)="data">
            <span>{{ data.value || '-' }}</span>
          </template>

          <!-- Column: Landmark -->
          <template #cell(nearest_landmark)="data">
            <span>{{ data.value || '-' }}</span>
          </template>

          <!-- Column: Reason -->
          <template #cell(reason_for_loan)="data">
            <span>{{ data.value || '-' }}</span>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge pill :variant="`light-${resolveStatusVariant(data.item.status)}`" class="text-capitalize">
              {{
                ['pending-product-delivery', 'flagged', 'admin-approve'].includes(data.item.status) ? 'Pending' : capitalizeFirstWord(data.item.status)
              }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'client-loans-single', params: { loan_id: data.item._id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </list-table>
      </div>
    </div>

    <div class="d-md-none">
      <div class="d-flex justify-content-end align-items-center">
        <b-button
            variant="primary"
            :to="{ name: 'client-select-loan-product' }"
            :disabled="!isLoansModuleEnabled"
        >
          <span class="text-nowrap">Get New Loan</span>
        </b-button>

      </div>
      <div>
        <loan-list-filters :status-filter.sync="statusFilter" />
      </div>
      <div>
        <div v-for="(loan, i) in loans" :key="i">
          <div class="border-bottom">
            <div>
              <div class="d-flex align-items-center justify-content-between mb-50">
                <div></div>
                <div>
                  <b-badge
                      pill
                      :variant="`light-${resolveStatusVariant(getValueFromSource(loan, 'status'))}`"
                      class="text-capitalize my-1"
                  >
                    {{ getUserStatusText(getValueFromSource(loan, 'status')) }}

                  </b-badge>
                </div>
              </div>

              <div class="mb-1">
                <small>Amount</small>
                <h1> <small class="text-black">Ghc </small> {{ formatMoney(getValueFromSource(loan, 'amount') ) }}</h1>
              </div>


              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div class="">
                    <small class="mr-1">Reason </small>
                    <h5 class="p-0 m-0">
                      {{ getValueFromSource(loan, 'reason_for_loan') }}
                    </h5>
                  </div>
                </div>
                <div class="ml-2">
                  <router-link :to="{ name: 'client-loans-single', params: { loan_id: getValueFromSource(loan, '_id') } }">
                    <b-button variant="primary">
                      Details
                    </b-button>
                  </router-link>
                </div>
              </div>


              <div class="mt-2">
                <p class="border-left pl-1">
                  {{ formatDate(getValueFromSource(loan, 'created'), 'dd MMMM yyyy') }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</b-overlay>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt";
import {
  debounce,
  get
} from "lodash";
import {
  formatDate,
  formatMoney
} from "@core/utils/utils";
import ListTable from "@core/components/ListTable/ListTable.vue";
import LoanListFilters from "@/pages/client/loans/list/LoanListFilters.vue";
import {
  BOverlay,
  BDropdown,
  BBadge,
  BButton,
  BDropdownItem,
  BDropdownGroup,
} from "bootstrap-vue";

const watchHandler = {
  handler() {
    this.debounceFetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler() {
    this.currentPage = 1;
    this.debounceFetchAllData()
  },
  immediate: false
}

export default {
  name: "ListLoans",
  components: {
    LoanListFilters,
    ListTable,
    BBadge,
    BButton,
    BOverlay,
    BDropdown,
    BDropdownGroup,
    BDropdownItem
  },
  data() {
    return {
      loans: [],
      loading: false,
      statusFilter: "",
      tableColumns: [{
          key: 'amount',
          sortable: false,
          label: 'Amount',
          formatter: val => `¢${formatMoney(val)}`
        },
        {
          key: 'purpose',
          sortable: false
        },
        {
          key: 'location',
          sortable: false,
          label: "Location"
        },
        {
          key: 'nearest_landmark',
          sortable: false,
          label: "Nearest Landmark"
        },
        {
          key: 'reason_for_loan',
          sortable: false,
          label: "Reason For Loan"
        },
        {
          key: 'status',
          sortable: false
        },
        {
          key: 'created',
          sortable: false,
          formatter: val => `${formatDate(val)}`,
          label: 'Date',
        },
        {
          key: 'actions'
        },
      ],

      debounceFetchAllData: null,
    }
  },
  computed: {
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value);
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get() {
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value) {
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    perPage: watchHandlerRequiresPageReset,
    statusFilter: watchHandlerRequiresPageReset,
    user_id: watchHandlerRequiresPageReset,
    currentPage: watchHandler,
    userId: {
      handler(user_id) {
        if (user_id) {
          this.user_id = user_id
        }
      },
      immediate: true
    },

  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchLoanRequests, 500);

    const {
      status,
      from,
      to,
      user_id,
      page = 1,
      search = "",
      limit = this.perPage
    } = this.$route.query;

    this.dateEnd = to;
    this.perPage = +limit;
    this.dateStart = from;
    this.user_id = user_id;
    this.currentPage = +page;
    this.searchFilter = search;
    this.statusFilter = status;

    this.$socket.client.on("REFRESH_PAGE_DATA", (payload) => {
      if (payload.name && payload.name === this.$route.name) {
        this.fetchLoanRequests()
      }
    });
  },
  methods: {
    async fetchLoanRequests() {
      try {
        const query = {
          limit: this.perPage,
          page: this.currentPage,
          status: this.statusFilter,
        };

        this.$router.push({
          query
        }).catch(() => {});

        const request = await useJwt.clientService.fetchLoanRecords(query);
        const {
          data,
          pagination
        } = request.data;
        this.totalRecords = pagination.totalRecords
        this.loans = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      }
    },
    onRowClicked(rowItem) {
      this.$router.push({
          name: 'client-loans-single',
          params: {
            loan_id: rowItem._id
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
