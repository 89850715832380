<template>
  <b-row>
    <b-col cols="12" md="3">
      <div>
        <label>Status</label>
        <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker
  },
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      statusOptions: [
        { label: 'Paid', value: 'paid' },
        { label: 'Draft', value: 'draft' },
        { label: 'Overdue', value: 'overdue' },
        { label: 'Pending', value: 'pending' },
        { label: 'Approved', value: 'approved' },
        { label: 'Declined', value: 'declined' },
        { label: 'Disbursed', value: 'disbursed' },
        { label: 'Pending Product Delivery', value: 'pending-product-delivery' },
      ],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
